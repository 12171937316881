import uiReducer from "./uiReducer";
import agenciesReducer from "./agenciesReducer";
import authReducer from "./authReducer";
import featureReducer from "./featureReducer"
import licenseReducer from "./licenseReducer";
import addonsReducer from "./addonsReducer";
import agencyAddonsReducer from "./agencyAddonsReducer";
import searchReducer from "./searchReducer";
import inviteReducer from "./inviteReducer";
import userReducer from "./userReducer";
import userinfoReducer from "./userinfoReducer";
import subscriptionsReducer from "./subscriptionReducer"
import flozySupportReducer from "./flozySupportReducer";
import referralReducer from "./referralReducer";
import mainModuleReducer from "./mainModuleReducer";
import applicationModuleReducer from "./applicationModuleReducer";
import faqReducer from "./faqReducer";
import collab from "./collabReducer";
import collabReducer from "./collabReducer";
import commissionsReducer from "./commissionsReducer";
import authUserGroupReducer from "./authUserGroup"
import agencyDashboardReducer from "./agencyDashboardReducer";
import userProfileReducer from "./userProfileReducer";
import passwordUpdateReducer from "./passwordUpdateReducer";
import planReducer from "./planReducer"

const reducers = {
    agencies: agenciesReducer,
    auth: authReducer,
    feature: featureReducer,
    license: licenseReducer,
    ui: uiReducer,
    addons:addonsReducer,
    agencyAddons:agencyAddonsReducer,
    search:searchReducer,
    Invite:inviteReducer,
    user:userReducer,
    userinfo:userinfoReducer,
    subscriptions:subscriptionsReducer,
    support:flozySupportReducer,
    referral:referralReducer,
    mainModule:mainModuleReducer,
    // mainModule:mainModuleReducer
    applicationModule: applicationModuleReducer,
    faq: faqReducer,
    collab,collabReducer,
    commissions:commissionsReducer,
    authUserGroup:authUserGroupReducer,
    agencyDashboard:agencyDashboardReducer,
    profileUser: userProfileReducer,
    passwordUser:passwordUpdateReducer,
    plan:planReducer,
    
}

export default reducers